import React from 'react';

const HeroVideo = () => {
    return (
        <video autoPlay loop muted className="w-full">
            {/* <source src="https://adpbu.s3.amazonaws.com/RC_RestrictedContent/CVI_ValueSkillsIntroVideo_v4/Corporate+Visions_ADP+Intro+Video.mp4" type="video/mp4" /> */}
            {/* <source src="https://adpbu.s3.amazonaws.com/SalesPlusVideos/SLP+Hype+v2_2.mp4" type="video/mp4" /> */}
            {/* <source src="https://adpbu.s3.amazonaws.com/SalesPlusVideos/S01E01_-_November_2023_Update_(HD_1080_-_WEB_(H264_4000)).mp4" type="video/mp4" /> */}
            <source src="https://adpbu.s3.amazonaws.com/SalesPlusVideos/202402+SLP_JO_Quarterly+Update_v01.mp4" type="video/mp4" />
            {/* <source src="demo.mp4" type="video/mp4" /> */}
        </video>
    )
}

export default HeroVideo;