import React from 'react';
import { useState, useEffect } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import SwimLaneItem from './SwimLaneItem';
import collections from '../data/Collections';

function SwimLaneOriginalSeries( props ) {
    const { name, playlist, media, index, toggleModal, collection, playlists } = props;

    function getMediaWithId(id) {
        var episode = media.find(ep => {
            return ep.id === id
        });

        return episode;
    }

    function getCollectionForPlaylist(playlistId) {
        var newArr = collections.filter(function (c) {
            return c["playlists"].flatMap((p) => p["playlistId"]).includes(playlistId);
        })

        return newArr.length > 0 ? newArr[0] : "No Collection";
    }

    function getPlaylistWithId(id) {
        var playlist = playlists.find(pl => {
            return pl.id === id
        });
        //console.log('what is this: ' + playlist);
        return playlist;
    }

    function getFirstEpisodeForCollection(collection) {
        let pl = getPlaylistWithId(collection["playlists"][0]["playlistId"]);
        let idArr = pl["playlistContent"].split(',');
        return idArr[0];
    }

    useEffect(() => {
        //console.log('here are the playlists:' + playlists)
    }, [])

    const slideLeft = () => {
        var slider = document.getElementById('slider' + index)
        slider.scrollLeft = slider.scrollLeft - 1500
    }

    const slideRight = () => {
        var slider = document.getElementById('slider' + index)
        slider.scrollLeft = slider.scrollLeft + 1500
    }

    return (
        <div className="items-center">
            <p className="2xl:text-4xl text-2xl font-medium text-gray-100 pl-16">{name}</p>
            <div className="relative flex items-center">
                <MdChevronLeft className="opacity-50 fill-white cursor-pointer hover:opacity-100" onClick={slideLeft} size={40} />
                <div id={'slider' + index} className="w-full h-{100} overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide p-4">
                {collections.map((collection) => (
                    // <img key={collection.name} onClick={() => toggleModal(true, collection, getFirstEpisodeForCollection(collection))} src={collection["thumbnail"]} className="2xl:h-52 h-32 rounded-md 2xl:mx-3 mx-2 inline-block z-30 hover:z-40 2xl:hover:scale-110 hover:scale-105 transform transition duration-300 hover:drop-shadow-lg hover:cursor-pointer" />
                    <div onClick={() => toggleModal(true, collection, getFirstEpisodeForCollection(collection))} className="relative inline-block 2xl:hover:scale-110 hover:scale-105 transform transition duration-300 hover:drop-shadow-lg hover:cursor-pointer 2xl:mx-3 mx-2">
                    <img key={collection.name} src={collection["thumbnail"]} className="2xl:h-52 h-32 relative inline-block 2xl:hover:scale-110 hover:scale-105 transform transition duration-300 hover:drop-shadow-lg hover:cursor-pointer 2xl:rounded-md rounded-sm" />
                    <div className="absolute top-0 left-0 right-0 opacity-0 transition ease-in-out duration-500 bg-gradient-to-b from-zinc-900 hover:opacity-90 2xl:h-52 h-32 aspect-[16/9] 2xl:rounded-md rounded-sm">
                        <div className="flex flex-col 2xl:h-52 h-32 aspect-[16/9]">
                            <div className="basis-1/2 text-white font-medium 2xl:text-sm text-xs p-4 whitespace-pre-line">
                                {collection.name}
                            </div>
                            <div className="basis-1/2 flex items-end text-white font-medium 2xl:text-sm text-xs p-4 whitespace-pre-line">
                                <p className="bg-zinc-900 px-1 rounded-sm"></p>
                            </div>
                        </div>
                    </div>
                    </div>
                ))}
                </div>
                <MdChevronRight className="opacity-50 fill-white cursor-pointer hover:opacity-100" onClick={slideRight} size={40} />
            </div>
        </div>
    )
}
export default SwimLaneOriginalSeries

//"1_h9x7nakh,1_zwwp0hwn,1_yegu3xqp,1_uenruxn4"