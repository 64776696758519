import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import SearchBar from '../components/SearchBar'
import collections from '../data/Collections';
import ModalVideoInfo from '../components/ModalVideoInfo'
import ShareMediaLink from "../components/ShareMediaLink"
import { ClipboardDocumentIcon, LinkIcon } from '@heroicons/react/20/solid'

import sha1 from 'crypto-js/sha1';

function Library() {

    const widgetId = "_1922831";
  const appTokenId = "1_sw1ywh3f";
  const appTokenValue = "77ed7f15a6ce2a399fb939cc6a4bb288";
  const sessionUserId = "lance.clack@adp.com";

  //const playlistIdFilter = "1_sxzohcku,1_bv779c80,1_1sa9fr0z,1_t58lg6vo,1_8kmv4ads,1_b1rth3f1,1_z1oh8ikk,1_x8xhe2lb,1_7v9rye8c,1_cn52itun,1_1twn39ze,1_den2ighe,1_8pslyeh9,1_etj6a8wi,1_o18uslgx,1_e3nh1fws,1_obrrq4wx,1_ttnnf5p6,1_fc0bozy2,1_2ucze55f,1_dokkeujm,1_sr973vbx,1_frojej82,1_92xlit3i,1_sopda4fd,1_rx8esl23,1_mqftifgq,1_4wlpkuxp,1_wziofwq1,1_19bjuagq,1_1ecn6mbt,1_0gmhccos,1_m4764auh,1_96onzy8c,1_1d2ujv9u,1_o7bzs7af,1_w1qtb15f,1_7lp10jzu,1_m75ds47v,1_6ipvl792,1_p2b3zuue,1_2d8t8b3s,1_78eg4g7b,1_i2ksdzjt,1_f4p17xa0,1_mr7yvgsl,1_7gyugv9b,1_r0rgpjp7,1_1kueex7i,1_ao13z6kl,1_8krfol12,1_5t9zu1ww,1_m14m0wrv,1_9gvxxk77,1_peqar7zu,1_artiiqrc,1_0urc0pe9";
  const playlistIdFilterA = "1_sxzohcku,1_bv779c80,1_1sa9fr0z,1_t58lg6vo,1_8kmv4ads,1_b1rth3f1,1_z1oh8ikk,1_x8xhe2lb,1_7v9rye8c,1_cn52itun,1_1twn39ze,1_den2ighe,1_8pslyeh9,1_etj6a8wi,1_o18uslgx,1_e3nh1fws,1_obrrq4wx,1_ttnnf5p6,1_fc0bozy2,1_2ucze55f,1_dokkeujm,1_sr973vbx,1_frojej82,1_92xlit3i,1_sopda4fd,1_rx8esl23,1_mqftifgq,1_4wlpkuxp,1_wziofwq1,1_19bjuagq,1_1ecn6mbt,1_0gmhccos,1_m4764auh,1_96onzy8c,1_1d2ujv9u,1_o7bzs7af,1_w1qtb15f,1_7lp10jzu,1_m75ds47v,1_6ipvl792,1_p2b3zuue,1_2d8t8b3s";
  const playlistIdFilterB = "1_78eg4g7b,1_i2ksdzjt,1_f4p17xa0,1_mr7yvgsl,1_7gyugv9b,1_r0rgpjp7,1_1kueex7i,1_ao13z6kl,1_8krfol12,1_5t9zu1ww,1_m14m0wrv,1_9gvxxk77,1_peqar7zu,1_artiiqrc,1_0urc0pe9,1_nbr391zn,1_yoj3yhob,1_lxvj66bu,1_8w3uqiru,1_3tkqxd53,1_ignr0wfy";

  const [kalturaSession, setKalturaSession] = useState("");
  const [media, setMedia] = useState([]);
  const [playlists, setPlaylists] = useState([]);

  const [selectedCollection, setSelectedCollection] = useState(collections[0]);
  const [selectedEpisodeId, setSelectedEpisodeId] = useState("");

  const [allPlaylistIds, setAllPlaylistIds] = useState([]);

  const [allPlaylistContent, setAllPlaylistContent] = useState([]);

  const [q, setQ] = useState("");

  const [searchParam] = useState(["name", "description"]);

  const [showModal, setShowModal] = useState(false);

  const [contentTags, setContentTags] = useState([]);
  const [contentHashTags, setContentHashTags] = useState([]);

  const handleOnCloseModal = () => setShowModal(false)

  const toggleModal = (bool, collection, episodeId) => {
    setSelectedCollection(collection)
    setSelectedEpisodeId(episodeId)
    setShowModal(bool)
    //console.log("Button Clicked")
  }

  const startWidgetSession = async () => {
    axios.post('https://www.kaltura.com/api_v3/service/session/action/startWidgetSession?widgetId=_1922831&format=1')
    .then(res => {
      const json = res.data;
      //getTokenHash(json["ks"]);
      const stringToHash = json["ks"] + appTokenValue;
      const hashed = sha1(stringToHash);
      startAppTokenSession(json["ks"], hashed);
    })
  }

  const getTokenHash = async (ks) => {
    axios.get('https://api.hashify.net/hash/sha1/hex?value=' + ks + appTokenValue)
    .then(res => {
      const json = res.data;
      startAppTokenSession(ks, json["Digest"]);
      
    })
  }

  const startAppTokenSession = async (ks, tokenHash) => {
    axios.post('https://www.kaltura.com/api_v3/service/apptoken/action/startSession?ks=' + ks + '&id=' + appTokenId + '&tokenHash=' + tokenHash + '&userId=' + sessionUserId + '&format=1')
    .then(res => {
      const json = res.data;
      //console.log(json["ks"]);
      setKalturaSession(json["ks"]);
      /////getMedia(json["ks"]);
      getPlaylists(json["ks"]);
    })
  }

 const getPlaylists = async (ks) => {
    console.log('getting playlists...');
    axios.post('https://www.kaltura.com/api_v3/service/playlist/action/list?ks=' + ks + '&format=1&filter[objectType]=KalturaMediaEntryFilter&filter[idIn]=' + playlistIdFilterA + '&filter[categoriesFullNameIn]=Sales Learning Plus&pager[objectType]=KalturaPager&pager[pageSize]=500')
    .then(res => {
      const json = res.data;
      //////setPlaylists(json["objects"]);
      //console.log(json["objects"].length + ' playlists');
      console.log(json["objects"]);
      let playlistContent = json["objects"].flatMap((pl) => pl["playlistContent"].split(','));
      //console.log('playlistContent size: ' + playlistContent.length)
      /////setAllPlaylistContent(playlistContent);
      getMedia(ks, json["objects"], playlistContent)
    })
  }

  const getMedia = async (ks, playlistArray, playlistContent) => {
    console.log('Getting media...');
    //console.log('getMedia')
    //axios.post('https://www.kaltura.com/api_v3/service/media/action/list?ks=' + ks + '&format=1&pager[objectType]=KalturaPager&pager[pageSize]=500&filter[objectType]=KalturaMediaEntryFilter&filter[categoriesFullNameIn]=Sales Learning Plus')
    axios.post('https://www.kaltura.com/api_v3/service/media/action/list?ks=' + ks + '&format=1&pager[objectType]=KalturaPager&pager[pageSize]=500&filter[objectType]=KalturaMediaEntryFilter&filter[idIn]=' + playlistContent)
    .then(res => {
      const json = res.data;
      //console.log('json response: ' + json["pager"]["pageSize"]);
      //setMedia(json["objects"]);
      //console.log('mediaaaa ' + media.length);
      //console.log(json["objects"].length + ' media');
      //console.log(json["objects"]);
      //getTopVideos(ks);
      //console.log("media[0]: " + media[0]);
      getMorePlaylists(ks, playlistArray, json["objects"], playlistContent);
    })
  }

  const getMorePlaylists = async (ks, playlistArray, mediaArray, p) => {
    console.log('getting more playlists...');
    axios.post('https://www.kaltura.com/api_v3/service/playlist/action/list?ks=' + ks + '&format=1&filter[objectType]=KalturaMediaEntryFilter&filter[idIn]=' + playlistIdFilterB + '&filter[categoriesFullNameIn]=Sales Learning Plus&pager[objectType]=KalturaPager&pager[pageSize]=500')
    .then(res => {
      const json = res.data;
      //////setPlaylists(json["objects"]);
      setPlaylists(playlistArray.concat(json["objects"]));
      //console.log('playlists: ' + playlists.length);
      //console.log(json["objects"].length + ' playlists');
      console.log(json["objects"]);
      let playlistContent = json["objects"].flatMap((pl) => pl["playlistContent"].split(','));
      //console.log('more playlistContent size: ' + playlistContent.length)
      /////setAllPlaylistContent(playlistContent);
      setAllPlaylistContent(p.concat(playlistContent));
      getMoreMedia(ks, mediaArray, playlistContent)
    })
  }

  const getMoreMedia = async (ks, mediaArray, morePlaylistContent) => {
    console.log('Getting more media...')
    axios.post('https://www.kaltura.com/api_v3/service/media/action/list?ks=' + ks + '&format=1&pager[objectType]=KalturaPager&pager[pageSize]=500&filter[objectType]=KalturaMediaEntryFilter&filter[idIn]=' + morePlaylistContent)
    .then(res => {
      const json = res.data;
      let allMedia = mediaArray.concat(json["objects"]);
      //setMedia(json["objects"]);
      setMedia(allMedia);
    })
  }

  function getTags(tagString) {

    //let tags = media.map((m) => m["tags"].split(', ') );
    let tagArray = tagString.split(', ');
    console.log(tagArray);
    return tagArray;
  }

  function onlyUniqueTags(value, index, array) {
    return array.indexOf(value) === index;
  }

  function checkTagStringLength(m) {
    return m["tags"] != null;
  }

  function buildTagCloud() {
    //let tags = media.map((m) => getTags(m["tags"]));
    //console.log(tags)
    //return tags;
    let tagStrings = media.filter(checkTagStringLength).map((m) => m["tags"].split(', ')).flat().filter(onlyUniqueTags);
    let hashTagStrings = tagStrings.filter(t => t.startsWith('#'));
    //let tagStrings = media.flatMap((m) => m["tags"]);
    //console.log(tagStrings);
    //console.log(hashTagStrings);
    setContentTags(tagStrings);
    setContentHashTags(hashTagStrings);
    //let flatTagStrings = tagStrings.flat();
    //console.log(tagStrings);
  }

  function checkSearchQuery(episode) {
    return episode["name"].toLowerCase().includes(q.toLowerCase()) || episode["description"]?.toLowerCase().includes(q.toLowerCase()) || episode["tags"]?.toLowerCase().includes(q.toLowerCase());
  }

  function getAllPlaylistIds() {
        let newArr = collections.flatMap((c) => c["playlists"].flatMap((p) => p["playlistId"]));
        //console.log('testFlatMap: ' + newArr);
        setAllPlaylistIds(newArr);
    }

  function filteredMedia() {
      return media.filter(function (m) {
          return allPlaylistContent.includes(m.id);
      })
  }

  function getPlaylistForEpisode(episodeId) {
      var newArr = playlists.filter(function (pl) {
          return pl["playlistContent"].includes(episodeId);
      });

      //return newArr.length > 0 ? newArr[0]["name"] : "No Playlist";
      return newArr.length > 0 ? newArr[0] : { "name": "No Playlist" };
  }

    function getCollectionForPlaylist(playlistId) {
        var newArr = collections.filter(function (c) {
            return c["playlists"].flatMap((p) => p["playlistId"]).includes(playlistId);
        })

        return newArr.length > 0 ? newArr[0] : "No Collection";
    }

    function getSearchStringFromURL() {
    const queryParameters = new URLSearchParams(window.location.search)
    const queryString = queryParameters.get("search")
    if ( queryString ) {
      console.log("Found search string in URL: " + queryString)
      setQ(queryString)
    } else {
      console.log("No search string found in URL.")
    }
  }
    

  useEffect(() => {
    startWidgetSession();
    getSearchStringFromURL();
    //buildTagCloud();
    //setQ("zip")
  }, []);

  useEffect(() => {
    if(media.length > 0) {
      buildTagCloud();
    }
  }, [media]);

    return (
        <div className="App">
            <div className="bg-zinc-900">
                <div className="px-10 pt-10">
                  <label htmlFor="email" className="block text-3xl font-medium leading-6 text-gray-100 pb-4">
                      {/* Search Library { String(contentTags.length) + ' Tags and ' + String(contentHashTags.length) + ' Hashtags' } */}
                      Search Library
                  </label>
                  <div className="relative flex flex-grow items-stretch focus-within:z-10 mt-2 gap-x-3">
                      <input
                      type="email"
                      name="email"
                      id="email"
                      className="block w-1/3 rounded-md border-0 py-1.5 text-lg text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-4"
                      placeholder="e.g, competitive intelligence"
                      value={q}
                      onChange={(e) => setQ(e.target.value)}
                      />
                      <button
                        type="button"
                        className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-gray-500 ring-1 ring-inset ring-gray-700 hover:bg-gray-100"
                        onClick={() => { navigator.clipboard.writeText("https://www.slplus.tv/library?search=" + q.replace("#", "%23"))}}
                      >
                        <ClipboardDocumentIcon className="-ml-0.5 h-5 w-5 text-gray-500" aria-hidden="true" />
                        
                      </button>
                  </div>
                  {/* <div class="flex flex-wrap gap-2 pt-10">
                    {contentHashTags.map((tag) => (
                      <a onClick={() => setQ(tag)} href="#" class="bg-violet-500 hover:bg-white hover:text-zinc-900 py-1 px-2 rounded-md text-lg text-white font-medium">{ tag }</a>
                    ))}
                </div> */}
                </div>
                <div className="mx-auto max-w-full overflow-hidden px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
                    <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-4 lg:grid-cols-6 lg:gap-x-8">
                    {filteredMedia().filter(checkSearchQuery).map((episode) => (
                        <a onClick={() => toggleModal(true, getCollectionForPlaylist(getPlaylistForEpisode(episode.id)["id"]), episode.id)} key={episode.id} href='#' className="group text-md">
                        <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-zinc-900 group-hover:opacity-75">
                            <img
                            src={episode.thumbnailUrl + '/width/240/height/135/type/3'}
                            alt=""
                            className="h-full w-full object-cover object-center"
                            />
                        </div>
                        <h3 className="mt-4 font-medium sm:text-md md:text-sm text-gray-100">{episode.name}</h3>
                        {/* <p className="text-gray-500">{episode.description}</p> */}
                        <p className="mt-2 text-gray-400">{episode.views + ' views'}</p>
                        </a>
                    ))}
                    </div>
                </div>
            </div>
            <ModalVideoInfo onClose={handleOnCloseModal} visible={showModal} kalturaSession={kalturaSession} collection={selectedCollection} media={media} playlists={playlists} episodeId={selectedEpisodeId} />
        </div>
    )
}

export default Library;